<template>
    <div class="content-wrap">
        <div class="content-header">
            <div class="header-title">
                <span>单位信息</span>
            </div>
        </div>
        <div class="content-tenant">
            <div class="tenant-details">
                <div>
                    <span>单位名称：</span><span>{{ tenant.name }}</span>
                </div>
                <div>
                    <span>注册时间：</span><span>{{ tenant.created }}</span>
                </div>
                <div>
                    <span>绑定手机号：</span><span>{{ tenant.phone }}</span>
                </div>
            </div>
            <div class="tenant-code">
                <div>
                    <span>单位二维码</span>
                </div>
                <div>
                    <div><img :src="tenant.qrCodeUrl" style="flex-shrink: 0; height: 100%"/></div>
                    <el-button type="primary" @click="handleOrCodeDownLoad()">
                        下载单位二维码
                    </el-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import {getTenant, downloadOrCode} from "@/service/organization"

    export default {
        name: "TenantDetails",
        created() {
            this.handleTenant();
        },
        data() {
            return {
                tenant: {
                    qrCodeUrl: null
                }
            }
        },
        methods: {
            //处理枚举请求
            handleTenant() {
                getTenant().then(res => {
                    if (res.success) {
                        this.tenant = res.data;
                    }
                })
            },
            /* 二维码下载 */
            async handleOrCodeDownLoad() {
                const res = await downloadOrCode();
                let url = window.URL.createObjectURL(new Blob([res.data], {
                    type: "image/jpeg"
                }));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', "二维码下载.jpeg");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
        }

    }

</script>

<style lang="scss" scoped>
    .content-wrap {
        width: 100%;
        min-height: calc(100% + 24px);;
        background-color: #fff;

        .content-header {
            padding: 24px;

            .header-title {
                span {
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }

                span:before {
                    content: "|";
                    display: inline-block;
                    font-size: 16px;
                    background-color: #333;
                    margin-right: 10px;
                }
            }

            border-bottom: 1px solid #E9E9E9;
        }

        .content-tenant {
            padding: 24px;
            display: flex;
            flex-direction: column;

            .tenant-details {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                div {
                    span:nth-child(1) {
                        margin-right: 6px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                    }

                    span:nth-child(2) {
                        margin-right: 100px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }

            .tenant-code {
                padding: 24px;
                margin-top: 12px;
                border: 1px solid #E9E9E9;
                height: 340px;

                div:nth-child(1) {
                    span {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                    }
                }

                div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    div {
                        width: 200px;
                        height: 200px;
                        border: 1px solid #E9E9E9;
                        margin-bottom: 24px;
                    }
                }
            }

        }
    }


</style>
